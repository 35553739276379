:root {
    --primary-color: #283048;
    --dark-bg: #F5F5F5;
    --light-dark-bg: #ECEFF1;
    --text-color: #333;
}

.keyword-planner-form {
    background: white;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    margin: 24px 0;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    align-items: start;
}

.form-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-field label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #374151;
}

.form-field input {
    height: 44px;
    padding: 0 16px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    font-size: 0.875rem;
    transition: all 0.2s;
    width: 100%;
}

.form-field input:focus {
    border-color: #05B24F;
    box-shadow: 0 0 0 3px rgba(5, 178, 79, 0.1);
    outline: none;
}

/* Override react-select styles */
.form-field .select__control {
    height: 44px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    box-shadow: none;
}

.form-field .select__control:hover {
    border-color: #05B24F;
}

.form-field .select__control--is-focused {
    border-color: #05B24F;
    box-shadow: 0 0 0 3px rgba(5, 178, 79, 0.1);
}

/* Remove unused classes */
.stats-grid,
.stat-card,
.stat-value,
.stat-label,
.results-grid,
.keyword-card,
.keyword-header,
.competition-badge,
.keyword-stats,
.stat-item {
    /* These classes can be removed as they're not used in the current implementation */
}

/* Remove duplicate button styles - keeping only the newer version */
.keyword-planner-form button,
.keyword-planner-form button:hover {
    /* These can be removed as there's a better .submit-button implementation */
}

.results-container {
    margin-top: 30px;
}

.results-section {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.results-section h3 {
    padding: 16px 20px;
    margin: 0;
    background: #f0f2f5;
    color: #4a5568;
    font-size: 1rem;
    border-bottom: 2px solid #e4e7eb;
}

.results-table {
    width: 100%;
    border-collapse: collapse;
}

.results-table th {
    position: sticky;
    top: 0;
    z-index: 3;
    background: #f1f5f9;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    color: #64748b;
    padding: 16px 20px;
    white-space: nowrap;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;
}

.results-table th span {
    margin-left: 8px;
    vertical-align: middle;
}

.results-table th svg {
    vertical-align: middle;
}

.results-table td {
    padding: 0.75rem 1.25rem;
    border-top: 1px solid #e4e7eb;
    color: #2c343f;
}

.error-message {
    background: #fee2e2;
    color: #dc2626;
    padding: 12px 16px;
    border-radius: 6px;
    margin: 16px 0;
}

.keyword-planner-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
}

.search-section {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
}

.search-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 1rem 0;
}

.search-section-header h3 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.collapse-icon {
    transition: transform 0.3s ease;
}

.collapse-icon.collapsed {
    transform: rotate(-180deg);
}

.section-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 1.5rem;
}

.search-box {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
}

.search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    pointer-events: none;
    z-index: 1;
}

.main-search-input {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    font-size: 1.1rem;
    border: 2px solid #e5e7eb;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    background: white;
}

.main-search-input:focus {
    border-color: #283048;
    box-shadow: 0 0 0 3px rgba(40, 48, 72, 0.1);
    outline: none;
}

.filters-container {
    background: transparent;
    padding: 0;
    margin-bottom: 2rem;
    box-shadow: none;
}

.filter-section, .advanced-section {
    margin-bottom: 1.5rem;
    padding: 0;
    background: transparent;
}

.filter-section:last-child, .advanced-section:last-child {
    margin-bottom: 0;
}

.filter-section h3, .advanced-section h3 {
    color: #283048;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.filter-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.filter-item {
    margin-bottom: 1rem;
}

.filter-item label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
    margin-bottom: 0.5rem;
}

.select-modern {
    width: 100%;
}

.select__control {
    border: 2px solid #e5e7eb !important;
    border-radius: 0.5rem !important;
    min-height: 2.75rem !important;
}

.select__control--is-focused {
    border-color: #2563eb !important;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1) !important;
}

.select-modern .select__control {
    border: 2px solid #e5e7eb !important;
    border-radius: 0.5rem !important;
    min-height: 2.75rem !important;
    background: white;
}

.select-modern .select__control--is-focused {
    border-color: #283048 !important;
    box-shadow: 0 0 0 3px rgba(40, 48, 72, 0.1) !important;
}

.submit-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    padding: 1.5rem 0 0;
    border-top: 1px solid #e5e7eb;
}

.submit-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #05B24F;
    color: white;
    padding: 0.75rem 2rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.submit-button:hover {
    background: #048f40;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(40, 48, 72, 0.2);
}

/* Enhanced Table Styles */
.results-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background: #f0f2f5;
}

.results-table-header h3 {
    padding: 0;
    border: none;
}

.table-actions {
    display: flex;
    gap: 12px;
}

.download-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    background: #05B24F;
    color: white;
    padding: 8px 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 0.875rem;
    transition: all 0.2s;
}

.download-btn:hover {
    background: #048f40;
}

.results-table th {
    position: sticky;
    top: 0;
    z-index: 3;
    background: #f1f5f9;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    color: #64748b;
    padding: 16px 20px;
    white-space: nowrap;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;
}

.results-table th span {
    margin-left: 8px;
    vertical-align: middle;
}

.results-table th svg {
    vertical-align: middle;
}

.results-table th:hover {
    background: #e9ecef;
}

.sort-icon {
    margin-left: 4px;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
    color: #94a3b8;
}

.stage-header {
    background: #f8fafc;
    color: #374151;
    font-weight: 600;
    padding: 12px 20px;
    border-top: 1px solid #e4e7eb;
}

.stage-header td {
    background: #334155 !important;
    color: white !important;
    font-size: 0.875rem;
    padding: 12px 20px !important;
    font-weight: 600;
    text-transform: uppercase;
}

.channel-header td {
    background: #f8fafc;
    font-weight: 500;
    font-size: 0.875rem;
    color: #475569;
    padding: 0.75rem 1.25rem !important;
    border-left: 4px solid #e2e8f0;
    position: sticky;
    top: 112px; /* Sum of table header and stage header heights */
    z-index: 1; /* Below the stage header */
    background: #f8fafc !important;
    border-left: 4px solid #e2e8f0;
    padding: 10px 20px !important;
    text-transform: uppercase;
    font-weight: 600;
}

.stage-header td::after,
.channel-header td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    background: #e4e7eb;
}

.results-table tr:not(.stage-header):not(.channel-header):hover {
    background: #f8fafc;
}

.results-table td {
    transition: background-color 0.2s;
}

.competition-badge {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
}

.competition-high {
    background: rgba(220, 38, 38, 0.1);
    color: #dc2626;
}

.competition-medium {
    background: rgba(245, 158, 11, 0.1);
    color: #d97706;
}

.competition-low {
    background: rgba(5, 178, 79, 0.1);
    color: #05B24F;
}

.form-container {
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    max-height: 1000px; /* Adjust this value based on your form's maximum height */
}

.form-container.collapsed {
    max-height: 0;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.stats-dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.stat-card {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    border: 1px solid #e5e7eb;
    transition: all 0.2s ease;
    cursor: pointer;
}

.stat-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.stat-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.stat-icon-wrapper {
    background: rgba(5, 178, 79, 0.1);
    padding: 0.75rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat-icon {
    color: #05B24F;
    width: 1.5rem;
    height: 1.5rem;
}

.stat-title {
    color: #6b7280;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
}

.stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
}

.stat-description {
    color: #6b7280;
    font-size: 0.875rem;
    margin-top: 1rem;
}

.badge {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.6875rem;
    font-weight: 600;
    letter-spacing: 0.05em;
}

.badge-stage {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
}

.badge-channel {
    background: #e2e8f0;
    color: #475569;
}

.badge-stat {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #64748b;
    font-size: 0.75rem;
    font-weight: 500;
}

.filter-item .select__multi-value {
    background-color: var(--primary-color);
    color: white;
}

.filter-item .select__multi-value__label {
    color: white;
}

.filter-item .select__multi-value__remove {
    color: white;
}

.filter-item .select__multi-value__remove:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
}
