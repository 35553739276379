.header {
  background: #1E2630;
  width: 75px;
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: white;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.header-nav {
  margin: 0;
  width: 100%;
  height: 100%;
}

.main-icon {
  display: flex;
  height: 36px;
  background-color: #181f27;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.header-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.header-nav li {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.header-nav a {
  position: relative;
}


.header-nav li.active .header-icon {
  color: white;
}

.header-nav li.active {
  background-color: #363E48 ; 
}

.header-nav li:hover {
  background-color: #363E48 ;
}

.header-nav li:hover::before {
  content: '';
  position: absolute;
  left: 0;  
  width: 5px;
  height: 100%;
  background-color: #05B24F; 
}

.header-nav li:hover::before {
  content: '';
  position: absolute;
  left: 0;  
  width: 5px;
  height: 100%;
  background-color: #05B24F; 
}

.header-nav li.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #05B24F; /* This sets the background color to a shade of green. */
}


.header-icon {
  color: white;
}

.header-nav li:hover {
  cursor: pointer;
}

.header-nav li:hover a {
  color: white;
}

.header-nav li:hover .header-icon {
  color: white;
}

.sub-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  width: 250px;
  margin-top:1px;
  margin-left: 1px;
  color: white;
  background-color: #363E48; /* Dark grey for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Stronger shadow for better depth */
  z-index: 100;
  overflow: hidden;
  border-radius: 8px; /* Rounded corners for the submenu */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
}

.header-nav li:hover .sub-menu {
  display: block;
  animation: slideIn 0.1s ease-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.sub-menu div {
  padding: 12px 20px;
  border-bottom: 1px solid #2c343f;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s;
  font-family: 'Roboto', sans-serif;

}

.sub-menu-title {
  font-family: 'Roboto', sans-serif;
  cursor: default;
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 10px; /* Added padding for better spacing */
  border-bottom: 1px solid #2c343f; /* Ensures the title is distinctly separated */
  color: #FFF; /* White color for visibility */
  align-items: center;
  justify-content: center;
  background-color: #1E2630;
}

.sub-menu-icon {
  width: 40px; /* Space between icon and text */
  margin-right: 10px;
  color: #b0b8c5; /* Soft light color for icons */
}

.sub-menu div:hover {
  background-color: #2c343f; /* Darker shade on hover */
  color: white; /* White text color for contrast on hover */
}



.sub-menu div:last-child {
  border-bottom: none;
}