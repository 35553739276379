.campaign-metrics {
    margin-top: 30px;
    color: #2c343f;
}

.metrics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.section-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c343f;
    margin: 0;
}

.view-all-btn {
    background: #f0f2f5;
    border: none;
    color: #2c343f;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.view-all-btn:hover {
    background: #e4e7eb;
}

.table-container {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    text-align: left;
    padding: 16px 20px;
    background: #f0f2f5;
    color: #4a5568;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 2px solid #e4e7eb;
}

td {
    padding: 16px 20px;
    border-top: 1px solid #e4e7eb;
    color: #2c343f;
}

.campaign-row {
    transition: background-color 0.2s;
}

.campaign-row:hover {
    background: #f8f9fa;
}

.campaign-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2c343f;
    min-height: 32px; /* Added to prevent layout shift */
}

.campaign-actions {
    visibility: hidden; /* Changed from display: none to prevent layout shift */
    gap: 8px;
    display: flex; /* Always display flex, but hidden by default */
    min-width: 120px; /* Reserve space for buttons */
}

.campaign-row:hover .campaign-actions {
    visibility: visible; /* Show on hover instead of changing display */
}

.action-btn {
    background: transparent;
    border: 1px solid #05B24F;
    color: #05B24F;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.75rem;
    cursor: pointer;
    transition: all 0.2s;
}

.action-btn:hover {
    background: #05B24F;
    color: #fff;
}

.channel-tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.channel-tag {
    padding: 4px 8px;
    background: #f0f2f5;
    border-radius: 4px;
    font-size: 0.75rem;
    color: #4a5568;
}

.performance-metrics {
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-height: 24px; /* Added to prevent layout shift */
}

.performance-item {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #4a5568;
}

.channel-name {
    color: #4a5568; /* Changed from white to a lighter gray */
}

.conversion-rate {
    color: #05B24F; /* Make conversion rate stand out */
}

.status-badge {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 0.75rem;
    text-transform: capitalize;
}

.status-badge.active {
    background: rgba(5, 178, 79, 0.2);
    color: #05B24F;
}

.status-badge.paused {
    background: rgba(220, 38, 38, 0.2);
    color: #dc2626;
}

.status-badge svg {
    font-size: 12px;
}

.budget-cell {
    color: #05B24F;
    font-weight: 600;
}