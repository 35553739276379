.start-button {
    width: 100%;
    display: flex;
    justify-content: center;
}

.wrap-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.excerpt {
    padding-left: 10px;
}

.greeting {
    padding-left: 10px;
    font-size: 2rem;
}

.metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.metric-card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease;
}

.metric-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.metric-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
}

.metric-info {
    flex-grow: 1;
}

.metric-title {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 5px;
}

.metric-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
}

.metric-icon {
    padding: 10px;
    background: #f0f0f0;
    border-radius: 8px;
    color: #666;
}

.metric-description {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 15px;
}

.metric-trend {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
}

.trend-up {
    color: #22c55e;
}

.trend-down {
    color: #ef4444;
}

.trend-label {
    color: #666;
    margin-left: 5px;
}