.start-button {
    width: 100%;
    display: flex;
    justify-content: center;
}

.wrap-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.excerpt {
    padding-left: 10px;
}

.greeting {
    padding-left: 10px;
    font-size: 2rem;
}