.sidebar {
  background: #F9F9F9;
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
}


.sidebar-right {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 0;
  align-items: center;
  justify-content: right;
}


.sidebar-nav {
  margin: 0;
  padding: 0;
  width: 100%;
}

.sidebar-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.sidebar-nav li {
  padding-left: 25px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}

.sidebar-nav a {
  position: relative;
}

.sidebar-nav li::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  width: 90%;
  height: 5px;
  background-color: #5C5C5C;
}

.sidebar-nav li:hover::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  width: 90%;
  height: 5px;
  background-color: #00cf5a;
}

.sidebar-nav li a {
  font-family: Montserrat, Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #5C5C5C;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
}

.sidebar-nav li.active a {
  color: #ffffff;
}

.sidebar-nav li.active .sidebar-icon {
  color: #70eaa6;
}

.sidebar-nav li.active::after {
  background-color: #00cf5a;
}

.sidebar-icon {
  color: #5C5C5C;
  padding-right: 10px;
  width: 12%;
}

.sidebar-nav li:hover {
  cursor: pointer;
}

.sidebar-nav li:hover a {
  color: white;
}

.sidebar-nav li:hover .sidebar-icon {
  color: #00cf5a;
}
