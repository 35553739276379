
/* Header and Dock Styles */
.header-dock {
    padding: 0;
}

.header-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-side-form {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

/* Form Styling */
.form {
    background-color: #ffffff;
    width: 60%;
    border-radius: 8px;
    margin-right: auto;
}

label {
    display: block;
    margin-bottom: 5px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
}

input[type="text"], .account-select > div, .import-select > div {
    width: 100%;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding does not affect width */
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding does not affect width */
}

.button-shape {
    margin-top: 2rem;
}

.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.confirmation-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.confirmation-modal h4 {
    color: #333;
    font-size: 18px;
    margin-bottom: 5px;
}

.confirmation-modal p {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

.modal-actions {
    display: flex;
    justify-content: center;
}

.btn-confirm, .btn-cancel {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    margin: 0 10px;
}

.btn-confirm {
    background-color: #4CAF50;
    color: white;
}

.google-signin-button {
    display: flex;
    align-items: center;
    background-color: #4285F4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}