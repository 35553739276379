.data-sources {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    height: 100%;
    gap: 25px; /* Space between items */
    margin-top: 2rem;
    margin-bottom: 1rem;
    overflow-y: auto; 
    background: white;
    height: 100%;
}

/* Modern scrollbar */
.data-sources::-webkit-scrollbar {
    width: 8px;
}

.data-sources::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.data-sources::-webkit-scrollbar-thumb {
    background: #888;
}

.data-sources::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.data-source-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    padding: 10px;
    max-height: 80px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease-in-out;
}

.data-source-item:hover {
    box-shadow: 0 4px 10px rgba(0,0,0,0.15);
}

.data-source-icon {
    margin-left: 2rem;
}


.data-source-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.data-source-name {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
}

.data-source-status {
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 5px;
    text-align: center;
    width: 100px;
}
 
.header-side {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .close-side {
    cursor: pointer;
    display: flex;
    float: right;
    margin-bottom: 1rem;
  }