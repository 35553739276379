.button-shape {
    height: 40px;
    width: 150px;
    border-radius: 5px;
    background-color: #05B24F;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
  }
  
  .button-shape p {
    margin: 0;
    height: 100%;
    font-weight: bold;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
