/* login.css */
.svg-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust spacing as necessary */
    grid-auto-rows: 100px; /* Adjust height as necessary */
    font-size: 1px;
    z-index: -100;
  }

  .plus-icon-container {
    transition: transform 0.1s ease-out; /* Smooth out the transition */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex; /* Adjust display to suit your SVG distribution */
    align-items: center;
    justify-content: center;
  }

  .main-login-container {
    background-color: #1E2630;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Keeps SVG within the boundaries */
  }
  

.login-external-box {
    width: 50%;
    height: 50%;
    border-radius:30px;
    display: flex;
    z-index: 100;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 18px;
}

.login-sidebar {
    border-radius: 30px;
    left:0;
    width: 100%;
    height: 100%;
    background-color: #1E2630;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.login-networks-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}


.login-form {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-textfield{
    display: flex;
    height: 30px;
    margin: 0;
    padding: var(--inputPaddingV) var(--inputPaddingH);
    background-color: rgba(255, 255, 255, 0.952);
    color: inherit;
    font-family: inherit;
    font-size: var(--inputFontSize);
    font-weight: b;
    line-height: var(--inputLineHeight);
    border: none;
    border-radius: 0.4rem;
    transition: box-shadow var(--transitionDuration);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
}

.login-label{
    font-weight: bold;
    margin:0;
    padding-bottom: 5px;
    padding-top: 15px;
}

.login-button-container {
    padding-top: 10px;
}

.login-error-message {
    font-weight: bold;
    color: rgb(146, 8, 8);
    padding-top: 20px;
}

.login-button {
    background-color: #00cf5a; /* Green */
    border: none;
    color: black;
    border-radius: 25px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0;
    height: 100%;
    width: 100%;
    font-weight: 700;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer
}