.page-header {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }

.title-header {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    padding-left: 10px;
    flex: 1;
    font: 400 15px/24px Figtree,sans-serif;
    letter-spacing: 0;
    text-transform: none;
    color: #2c3546;
    font-size: 16px;
    line-height: 32px;
    box-sizing: border-box;
    transition: all .5s ease;
    float: left;
}

.title-header h1 {
  margin-bottom:0
}

.subtitle-header p{
  font: 400 15px/24px Figtree,sans-serif;
  letter-spacing: 0;
  text-transform: none;
  box-sizing: border-box;
  transition: all .5s ease;
  color: #656f85;
  margin-right: 16px;
  font-size: 15px;
  line-height: 24px;
}