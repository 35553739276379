:root {
    --primary-color: #283048; /* Deep blue-gray */
    --dark-bg: #F5F5F5; /* Soft light gray */
    --light-dark-bg: #ECEFF1; /* Light gray */
    --text-color: #333; /* Dark gray */
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    text-align: left;
    padding: 16px 20px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}
table tr {
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

table tr:hover {
    background: #f8f9fa; /* Light grey background on hover */
}
th {
    background: #f0f2f5;
    color: #4a5568;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 2px solid #e4e7eb;
}

td {
    padding: 16px 20px;
    border-top: 1px solid #e4e7eb;
    color: #2c343f;
}

.sources-list-container {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 20px;
}

.header-compartiment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.status {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 0.75rem;
    text-transform: capitalize;
    background: rgba(5, 178, 79, 0.2);
    color: #05B24F;
}


.side-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    padding: 2rem;
    height: 100%;
    background-color: white;
    transform: translateX(100%);
    transition: transform 0.2s ease-in-out;
    z-index: 1000;
  }
  
  .side-panel.open {
    transform: translateX(0);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }
  
  .overlay.show {
    display: block;
  }
