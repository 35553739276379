.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 4rem); 
  overflow-y: auto; 
  
  /* background-color: #D7DDD7; */
  scrollbar-width: thin;
  scrollbar-color: #888 #D7DDD7;
}

.content-box {
  width: 90%;
  height: 100%;
  padding: 0rem 1rem 0rem 1rem;
}

/* Optional: Smooth scrolling behavior */
.main-container {
  scroll-behavior: smooth;
}
