
.no-sources-container svg {
    margin-bottom: 1rem;
}

.no-sources-container {
    width: 100%;
    height: 50%;
    font-weight: 400;
    font-size: 13px;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    color-scheme: light;
    line-height: inherit;
    font-feature-settings: "kern";
    color: var(--chakra-colors-chakra-body-text);
    font-family: var(--chakra-fonts-body);
    box-sizing: border-box;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: var(--chakra-space-16);
    padding-bottom: var(--chakra-space-16);
    border-color: var(--chakra-colors-base-border);
    border-radius: var(--chakra-radii-md);
}
