@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, h1, h2, h3, p {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 15px;
}

/* H2 with heavy line */
h2 {
    position: relative;
    display: inline-block;
    font-weight: 700;
}

.box h2::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%; /* Full width underline */
    height: 4px;
    background-color: #00cf5a;
}

/* Box Styles */
.box {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 20px;
    padding-top: 0px;
    width: calc(33.33% - 40px);
    height: 260px;
    box-sizing: border-box;
    margin: 10px;
    display: inline-block;
    vertical-align: top;
    color: black;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* subtle shadow */
}

.icon {
    color: #00cf5a;
    font-size: 22px;
}

/* Responsive Design */
@media screen and (max-width: 960px) {
    .box {
        width: calc(50% - 30px);
    }
}

@media screen and (max-width: 600px) {
    .box {
        width: 100%;
        margin: 10px 0;
    }
}
