.header-button-container {
  position: relative;
  display: block; /* Ensures it takes full width available */
  min-width: 17rem; /* Takes full width of its parent */
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.header-button {
  display: flex;
  align-items: center;
  margin: 0;
  background-color: #F8F8F8;
  color: black;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 1px;
  width: 100%; /* Ensure full width */
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box; /* Include padding and border in width calculation */
  
  /* Add thin borders on the right and left */
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}


.header-button:hover {
  background-color: #aaaaaa20;
}

.icon-checkmark .checkmark {
  transition: transform 0.3s;
}

.icon-checkmark .checkmark.open {
  transform: rotate(180deg);
}

.dropdown-tab {
  position: absolute;
  background-color: #f9f9f9;
  color: black;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 10px;
  width: calc(100% - 20px); /* Full width of the parent minus padding */
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  top: 100%; /* Position right below the header button */
  left: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.text-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: left;
  margin-left: 2rem;
  align-items: left ;
}

.text-content p {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.dropdown-icon {
  color: #05B24F;
  margin-left: 10px;
  font-size: 1.6rem;
}

.dropdown-text p, .dropdown-text span {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-left: 1.2rem;
}

.dropdown-text span {
  font-size: 12px;
  color: #aaa;
}

@media (max-width: 768px) {
  .header-button, .dropdown-tab {
      width: 100%; /* Ensure full responsive width */
  }
}
